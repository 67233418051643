
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import Vue, { Component, PropType } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Connector, Project } from '../../types/main'
import {
    Activation,
    ChangeBotTriggerMode,
    EditTriggerStep,
    EditedActivation,
    IGCB2Connector,
    Message,
} from '@/types/GCB2'
import { CHANGE_BOT_TRIGGER_TYPES } from '../../vars/GCB2'
import _, { isNil, uniqueId } from 'lodash'
import { replaceElementAtIndex } from '../../utils'
import TextStep from '@/components/chisai/GCB2/bot/EditSteps/TextStep.vue'
import ReactionsAndAnswersStep from '@/components/chisai/GCB2/bot/EditSteps/ReactionsAndAnswersStep.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../../vars/general'
import api from '../../api/api'
import { canActivateTrigger } from '../../helpers/whatsapp'
import MediaStep from '../../components/chisai/GCB2/bot/EditSteps/MediaStep.vue'
import SendSettingsStep from '../../components/chisai/GCB2/bot/EditSteps/SendSettingsStep.vue'
import BanlistStep from '../../components/chisai/GCB2/bot/EditSteps/BanlistStep.vue'
import CustomReactionsStep from '../../components/chisai/GCB2/bot/EditSteps/CustomReactionsStep.vue'
import { isValidPhone } from '@root/src/helpers/validators/phoneValidator'
export default Vue.extend({
    components: {
        Breadcrumbs,
        SpinnerLoader,
        TextStep,
        SendSettingsStep,
    },

    data: () => ({
        CHANGE_BOT_TRIGGER_TYPES,
        loading: true as boolean,
        stepsIndexes: [] as number[] | number,
        activeStepsIndexes: [] as number[] | number,
        copyLoading: false as boolean,
        triggerIdFromQuery: null as any,
        triggers: {} as any,
        app: {} as any,
        trigger: {} as any,
        createOrEditLoading: false as boolean,
        mode: null as ChangeBotTriggerMode | null,
        _steps: null as EditTriggerStep[]
    }),
    watch: {
        stepsIndexes(): void {
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                this.activeStepsIndexes = Array.isArray(this.stepsIndexes)
                    ? [...this.stepsIndexes]
                    : [this.stepsIndexes].filter(el => !isNil(el))
            }
        },
        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.mode = this.$route.query.mode as ChangeBotTriggerMode
                this.triggerIdFromQuery = this.$route.query.id
            },
        },
    },
    computed: {
        steps:  {
            get: function (): EditTriggerStep[] {
                return this._steps || [
                {
                    id: 1,
                    title: 'Текст',
                    contentComponent: TextStep,
                    ref: 'setting-1',
                    saved: false,
                    saveBtnDisabled: false,
                },

                {
                    id: 2,
                    title: 'Ответы',
                    contentComponent: ReactionsAndAnswersStep,
                    ref: 'setting-2',
                    saved: false,
                    saveBtnDisabled: true,
                    showCondition: project => this.triggerIdFromQuery === 'afterVisit',
                },
                {
                    id: 3,
                    title: 'Ответы',
                    contentComponent: CustomReactionsStep,
                    ref: 'setting-3',
                    saved: false,
                    saveBtnDisabled: true,
                    showCondition: project => this.triggerIdFromQuery === 'notificBeforeRecord1Day',
                },
                {
                    id: 4,
                    title: 'Медиафайлы',
                    contentComponent: MediaStep,
                    ref: 'setting-4',
                    saved: false,
                    saveBtnDisabled: true,
                },
                {
                    id: 5,
                    title: 'Настройки отправки',
                    contentComponent: SendSettingsStep,
                    ref: 'setting-5',
                    saved: false,
                    saveBtnDisabled: true,
                    showCondition: project =>
                        [
                            'afterVisit',
                            'notificBeforeRecord1Day',
                            'notificBeforeRecord2Hours',
                            'afterRecordClientCome',
                        ].includes(this.triggerIdFromQuery),
                },
                {
                    id: 6,
                    title: 'Черный список',
                    contentComponent: BanlistStep,
                    ref: 'setting-6',
                    saved: false,
                    saveBtnDisabled: false,
                },
            ] as EditTriggerStep[]
            },
            set: function (newValue: EditTriggerStep[]) {
                this._steps = newValue
            },            
        },
        title(): string {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.create ? 'Новый триггер' : 'Редактирование'
        },
        expandDisabled(): boolean {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.edit
        },
        panelsIsMultiplie(): boolean {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.edit
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.whatsappBasis
            ) as Connector
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа для Idol Face',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Кого из кандидатов стоит позвать',
                    to: `/project/${this.project.id}/GCB2?section=bot`,
                },
                {
                    text: 'Бот',
                    to: '',
                    disabled: true,
                },
            ]
        },
        activeSwitchLabel(): string {
            return this.trigger.status === 'on' ? 'Деактивировать' : 'Активировать'
        },
        activeSwitchDisabled(): boolean {
            return !this.canActivateTrigger(this.triggerIdFromQuery, this.activeTriggersIds).canActivate
        },
        activationIsActiveTooltipText(): string | undefined {
            return this.canActivateTrigger(this.triggerIdFromQuery, this.activeTriggersIds).errorText
        },

        savedSteps(): any[] {
            return this.steps.filter(el => el.saved)
        },
        availableStepsIds(): number[] {
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                const nextStepIndex =
                    this.steps.findIndex(el => el.id === this.savedSteps[this.savedSteps.length - 1]?.id) + 1
                const nextStep = this.steps[nextStepIndex]
                return [...this.savedSteps.map(el => el.id), nextStep?.id]
            } else {
                return this.steps.map(el => el.id)
            }
        },
        availableSteps(): EditTriggerStep[] {
            return this.steps.filter(el => (el.showCondition ? el.showCondition(this.project) : true))
        },
        saveButtonDisabled(): boolean {
            return this.trigger.banOnClient.some((el: string) => !isValidPhone(el)) || this.app.banOnClient.some((el: string) => !isValidPhone(el))
        },
        activeTriggersIds(): string[] {
            return Object.keys(this.triggers)
                .map(id => ({ id, ...this.triggers[id as any] }))
                .filter(trigger => trigger.status === 'on')
                .map(trigger => trigger.id)
        },
    },
    methods: {
        canActivateTrigger,
        async init() {
            this.loading = true
            if (!this.mode) {
                this.mode = CHANGE_BOT_TRIGGER_TYPES.create as ChangeBotTriggerMode
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, {
                        mode: CHANGE_BOT_TRIGGER_TYPES.create,
                    }),
                })
            }
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                throw new Error('create trigger not implemented')
            }
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.edit && this.triggerIdFromQuery) {
                this.app = await api.networkFixer
                    .getApp({ connectorId: this.botConnector.connectorId })
                    .then(res => res.data!.data)
                this.triggers = this.app.triggers
                this.trigger = this.triggers[this.triggerIdFromQuery]
                this.stepsIndexes = this.steps.map((el, i) => i)
            }
            if (!this.trigger.answersStatus) {
                this.trigger.answersStatus = 'on'
            }
            if (!this.trigger.autoLeaveScoreYc) {
                this.trigger.autoLeaveScoreYc = false
            }
            if (!this.trigger.media) {
                this.trigger.media = {}
            }
            if (!this.trigger.banOnClient || (this.trigger.banOnClient.length === 1 && this.trigger.banOnClient[0] === '')) {
                this.trigger.banOnClient = []
            }
            if (!this.app.banOnClient || (this.app.banOnClient.length === 1 && this.app.banOnClient[0] === '')) {
                this.app.banOnClient = []
            }
            if (!this.trigger.answersList && this.triggerIdFromQuery === 'afterVisit') {
                this.trigger.answersList = {
                    1: ['1', '1-', '1+', '0', '0-', '0+', '1!', '0!', '1.'],
                    2: ['2', '2+', '2-', '2', '2.'],
                    3: ['3', '3+', '3-', '3'!, '3.'],
                    4: ['4', '4+', '4', '4!', '4.'],
                    5: ['5', '5+', '5-', '5!', '5.', '6', '10', '10!', '555555', '5!!'],
                }
            }
            if (!this.trigger.answersList && this.triggerIdFromQuery === 'notificBeforeRecord1Day') {
                this.trigger.answersList = {
                    1: ['1', 'ок', 'подтверждаю', 'да', 'хорошо', 'буду'],
                    2: ['2', 'отмените', 'не приду'],
                }
            }
            this.loading = false
        },
        saveStep(step: EditTriggerStep, index: number) {
            this.steps = replaceElementAtIndex(this.steps, index, Object.assign({}, step, { saved: true }))
            const nextStepIndex = index + 1
            this.stepsIndexes = nextStepIndex
            this.activeStepsIndexes = [nextStepIndex]
        },
        clear(step: EditTriggerStep) {
            switch (step.id) {
                case 1:
                    break
                case 2:
                    break
                default:
                    break
            }
            ;(this.$refs[step.ref] as any)[0].clear()
        },
        updateSaveButton(e: boolean, step: any, index: number) {
            this.steps = replaceElementAtIndex(
                this.steps,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            )
        },

        goToTriggersOverview() {
            this.$router.push(`/project/${this.project.id}/GCB2?section=bot`)
        },
        async createTrigger() {
            throw new Error('create trigger not implemented')
        },
        async updateTrigger() {
            this.createOrEditLoading = true
            const { data, error } = await api.networkFixer.updateApp({
                connectorId: this.botConnector.connectorId,
                data: {
                    banOnStaff: this.app.banOnStaff,
                    banOnClient: this.app.banOnClient,
                    triggers: {
                        [this.triggerIdFromQuery]: this.trigger,
                    },
                },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка при создании активации')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.goToTriggersOverview()
        },
        updateApp(v: any) {
            this.app = v
        },
    },

    async created() {
        await this.init()
    },
    mounted() {},
})
