const activations =  [
        {
            "activationDescription": "",
            "activationId": 14186,
            "activationName": "Администратор Idol Face ВОЛОГДА",
            "isRunning": false,
            "isRunningChangedAt": "2024-06-25T09:56:25.860Z",
            "updatedAt": "2023-10-16T08:26:36.418Z",
            "isAutomatic": false,
            "tags": [],
            "notProcessedForCardStat": 0,
            "order": 45,
            "priority": null,
            "totalForCardStat": 10,
            "errorsForCardStat": 0,
            "mode": null,
            "testModePhone": null,
            "time": [
                null,
                null
            ],
            "timezone": "+3"
        },
        {
            "activationDescription": "Осталось 10 дней до нормативного срока закрытия вакансии",
            "activationId": 14189,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "isRunning": true,
            "isRunningChangedAt": "2024-06-25T09:56:26.725Z",
            "updatedAt": "2023-10-16T08:27:56.083Z",
            "isAutomatic": false,
            "tags": [],
            "notProcessedForCardStat": 5,
            "order": 60,
            "priority": null,
            "totalForCardStat": 21,
            "errorsForCardStat": 0,
            "mode": null,
            "testModePhone": null,
            "time": [
                null,
                null
            ],
            "timezone": "+3"
        },
        {
            "activationDescription": "",
            "activationId": 14190,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Республиканская",
            "isRunning": true,
            "isRunningChangedAt": "2024-06-25T09:56:26.935Z",
            "updatedAt": "2023-10-16T08:27:51.303Z",
            "isAutomatic": false,
            "tags": [],
            "notProcessedForCardStat": 0,
            "order": 59,
            "priority": null,
            "totalForCardStat": 0,
            "errorsForCardStat": 0,
            "mode": null,
            "testModePhone": null,
            "time": [
                null,
                null
            ],
            "timezone": "+3"
        },
        {
            "activationDescription": "",
            "activationId": 14192,
            "activationName": "Массажист лица и тела КОММУНАРКА",
            "isRunning": false,
            "isRunningChangedAt": "2024-06-25T09:56:27.821Z",
            "updatedAt": "2023-10-16T08:27:31.080Z",
            "isAutomatic": false,
            "tags": [],
            "notProcessedForCardStat": 0,
            "order": 57,
            "priority": null,
            "totalForCardStat": 0,
            "errorsForCardStat": 0,
            "mode": null,
            "testModePhone": null,
            "time": [
                null,
                null
            ],
            "timezone": "+3"
        },
        {
            "activationDescription": "",
            "activationId": 14192,
            "activationName": "Менеджер по закупкам и снабжению",
            "isRunning": false,
            "isRunningChangedAt": "2024-06-25T09:56:27.821Z",
            "updatedAt": "2023-10-16T08:27:31.080Z",
            "isAutomatic": false,
            "tags": [],
            "notProcessedForCardStat": 0,
            "order": 57,
            "priority": null,
            "totalForCardStat": 0,
            "errorsForCardStat": 0,
            "mode": null,
            "testModePhone": null,
            "time": [
                null,
                null
            ],
            "timezone": "+3"
        },
    ]

const comms = [
    {
        "communicationId": "548223958",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_25",
        "clientNameWithNum": "Петрова Мария Викторовна",
        "status": "Нужно связаться позже",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Мария",
            "surname": "Петрова",
            "patronymic": "Викторовна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },

    {
        "communicationId": "548223958",
        "projectId": "PBGXYGSEA",
        "clientId": "+79208852059_25",
        "clientNameWithNum": "Петрова Мария Викторовна",
        "status": "Нужно связаться позже",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Мария",
            "surname": "Петрова",
            "patronymic": "Викторовна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },






    {
        "communicationId": "548223958",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_25",
        "clientNameWithNum": "Петрова Мария Викторовна",
        "status": "Нужно было связаться вчера или ранее",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Мария",
            "surname": "Петрова",
            "patronymic": "Викторовна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223959",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_26",
        "clientNameWithNum": "Васильев Иван Анатольевич",
        "status": "Нужно было связаться вчера или ранее",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Иван",
            "surname": "Васильев",
            "patronymic": "Анатольевич",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223960",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_27",
        "clientNameWithNum": "Федорова Светлана Юрьевна",
        "status": "Нужно связаться сегодня",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Светлана",
            "surname": "Федорова",
            "patronymic": "Юрьевна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223961",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_28",
        "clientNameWithNum": "Рыков Алексей Дмитриевич",
        "status": "Нужно связаться сегодня",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Алексей",
            "surname": "Рыков",
            "patronymic": "Дмитриевич",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223962",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_29",
        "clientNameWithNum": "Кузнецова Ольга Валентиновна",
        "status": "Нужно связаться",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Ольга",
            "surname": "Кузнецова",
            "patronymic": "Валентиновна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223963",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_30",
        "clientNameWithNum": "Григорьев Степан Александрович",
        "status": "Нужно связаться",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Степан",
            "surname": "Григорьев",
            "patronymic": "Александрович",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223964",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_31",
        "clientNameWithNum": "Леонова Ирина Олеговна",
        "status": "Передан Заказчику",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Ирина",
            "surname": "Леонова",
            "patronymic": "Олеговна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223965",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_32",
        "clientNameWithNum": "Морозова Светлана Владимировна",
        "status": "Передан Заказчику",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Светлана",
            "surname": "Морозова",
            "patronymic": "Владимировна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223966",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_33",
        "clientNameWithNum": "Жукова Надежда Викторовна",
        "status": "Нанят",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Надежда",
            "surname": "Жукова",
            "patronymic": "Викторовна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223967",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_34",
        "clientNameWithNum": "Смирнова Екатерина Петровна",
        "status": "Нанят",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Екатерина",
            "surname": "Смирнова",
            "patronymic": "Петровна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223968",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_35",
        "clientNameWithNum": "Иванова Татьяна Дмитриевна",
        "status": "Передан в другую вакансию",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Татьяна",
            "surname": "Иванова",
            "patronymic": "Дмитриевна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223969",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_36",
        "clientNameWithNum": "Соловьева Алёна Романовна",
        "status": "Передан в другую вакансию",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Алёна",
            "surname": "Соловьева",
            "patronymic": "Романовна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223970",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_37",
        "clientNameWithNum": "Герасимова Ирина Павловна",
        "status": "Черный список",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Ирина",
            "surname": "Герасимова",
            "patronymic": "Павловна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    },
    {
        "communicationId": "548223971",
        "projectId": "PBGXYGSEA",
        "clientId": "+79651057194_38",
        "clientNameWithNum": "Тимофеева Юлия Валерьевна",
        "status": "Черный список",
        "contactedByUserId": null,
        "contactedByUserName": null,
        "contactedAt": null,
        "clientVisitCnt": 0,
        "postponeTo": "1970-01-01T00:00:00.000Z",
        "activationId": 22482,
        "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
        "clientExtra": {
            "name": "Юлия",
            "surname": "Тимофеева",
            "patronymic": "Валерьевна",
            "last_visited": null
        },
        "isCreatedToday": false,
        "extra": {
            "data": {},
            "lastSendErrorCode": {}
        }
    }
]



const communications =  [
        {
            "communicationId": "548223944",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_15",
            "clientNameWithNum": "Иванов Алексей Сергеевич",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "15",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Ожидает собеседования",
            "contactedByUserId": "google-oauth2|112947983865517618451",
            "contactedByUserName": "nikmilhero@yandex.ru",
            "contactedAt": "2024-11-05T10:51:59.126Z",
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Алексей",
                "surname": "Иванов",
                "patronymic": "Сергеевич",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223949",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_16",
            "clientNameWithNum": "Кузнецов Сергей Вячеславович",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "16",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Ожидает собеседования",
            "contactedByUserId": "google-oauth2|112947983865517618451",
            "contactedByUserName": "nikmilhero@yandex.ru",
            "contactedAt": "2024-11-05T10:51:59.126Z",
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Сергей",
                "surname": "Кузнецов",
                "patronymic": "Вячеславович",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223950",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_17",
            "clientNameWithNum": "Егорова Наталья Олеговна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "17",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Ожидает собеседования",
            "contactedByUserId": "google-oauth2|112947983865517618451",
            "contactedByUserName": "nikmilhero@yandex.ru",
            "contactedAt": "2024-11-05T10:51:59.126Z",
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Наталья",
                "surname": "Егорова",
                "patronymic": "Олеговна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },

        {
            "communicationId": "548223951",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_18",
            "clientNameWithNum": "Смирнова Ирина Павловна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "18",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Ирина",
                "surname": "Смирнова",
                "patronymic": "Павловна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223952",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_19",
            "clientNameWithNum": "Павлов Алексей Петрович",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "19",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Алексей",
                "surname": "Павлов",
                "patronymic": "Петрович",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223953",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_20",
            "clientNameWithNum": "Романова Ирина Валерьевна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "20",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Ирина",
                "surname": "Романова",
                "patronymic": "Валерьевна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },

        {
            "communicationId": "548223954",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_21",
            "clientNameWithNum": "Семенова Елена Владимировна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "21",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться сегодня",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Елена",
                "surname": "Семенова",
                "patronymic": "Владимировна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223955",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_22",
            "clientNameWithNum": "Андреев Дмитрий Викторович",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "22",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться сегодня",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Дмитрий",
                "surname": "Андреев",
                "patronymic": "Викторович",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
        {
            "communicationId": "548223956",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_23",
            "clientNameWithNum": "Шмидт Ирина Викторовна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "23",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться сегодня",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Ирина",
                "surname": "Шмидт",
                "patronymic": "Викторовна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },

        {
            "communicationId": "548223957",
            "projectId": "PBGXYGSEA",
            "clientId": "+79651057194_24",
            "clientNameWithNum": "Борисова Людмила Аркадьевна",
            "clientComment": "",
            "hrefInfo": [
                {
                    "phone": "+79651057194",
                    "connectorType": "tel"
                },
                {
                    "phone": "+79651057194",
                    "client_id": "24",
                    "company_id": 60211,
                    "connectorType": "yClients"
                },
                {
                    "text": "Добрый день!",
                    "phone": "+79651057194",
                    "connectorType": "waLink",
                    "link_template": null
                }
            ],
            "status": "Нужно связаться сегодня",
            "contactedByUserId": null,
            "contactedByUserName": null,
            "contactedAt": null,
            "clientVisitCnt": 0,
            "postponeTo": "1970-01-01T00:00:00.000Z",
            "activationId": 22482,
            "activationName": "Массажист лица и тела ЯРОСЛАВЛЬ Заволга",
            "activationGroup": "",
            "textMessage": "",
            "serviceId": null,
            "serviceTitle": null,
            "comment": null,
            "rawId": null,
            "messageError": false,
            "clientExtra": {
                "name": "Людмила",
                "surname": "Борисова",
                "patronymic": "Аркадьевна",
                "last_visited": null,
                "autorecognize": true,
                "need_to_confirm": false,
                "is_manual_client": true
            },
            "isCreatedToday": false,
            "extra": {
                "data": {},
                "lastSendErrorCode": {}
            },
            "communicationTags": [],
            "activationPriority": 2
        },
    ...comms
    ]
;

export default {
    activations,
    communications
}